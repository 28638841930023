import React from "react"
import styled from "styled-components"
import {
  Column,
  Container,
  Heading,
  Head,
  Paragraph,
  Row,
  SecondarySection,
} from "../../components/components"
import { H4Raw } from "../../components/Heading/Heading"

const Imprint = () => {
  return (
    <>
      <Head pagePath="/impressum" />
      <SecondarySection>
        <Container>
          <Row>
            <Column style={{ maxWidth: "700px" }}>
              <Heading tag="h1">Impressum</Heading>
              <Heading2>
                Inhaltlich Verantwortlicher gemäß § 55 Abs. 2 RStV:
              </Heading2>
              <P>
                Incoqnito GmbH<br></br>
                Fössestr. 77A<br></br>
                DE-30451 Hannover<br></br>
                <a href="tel:+4951136055980">+49 511 3605598-0</a>
                <br></br>
                <a href="mailto:info@incoqnito.io">info@incoqnito.io</a>
                <br></br>
              </P>
              <Heading2>Vertreten durch Geschäftsführer</Heading2>
              <P>
                Patrick Flöter, Christian Birg<br></br>
                Amtsgericht Hannover, HRB 216094<br></br>
                USt.-IdNr. DE315467902
              </P>
              <Heading2>Haftungsausschluss</Heading2>
              <P>
                Die Incoqnito GmbH übernimmt keine Garantie für die
                Vollständigkeit, die Richtigkeit und die Aktualität der auf
                dieser Website bereitgestellten Informationen. Dies gilt auch
                für alle Links, auf die diese Website direkt oder indirekt
                verweist. Für Inhalte von Seiten, die über entsprechende Links
                erreicht werden, ist die Incoqnito GmbH nicht verantwortlich.
                Die Incoqnito GmbH behält sich das Recht vor, ohne vorherige
                Ankündigung Änderungen oder Ergänzungen der auf dieser Website
                bereitgestellten Inhalte und Informationen vorzunehmen.
              </P>
              <Heading2>Externe Links</Heading2>
              <P>
                Diese Website enthält u.a. Verknüpfungen zu anderen Websites
                Dritter ("externe Links"). Diese jeweiligen Websites unterliegen
                der Haftung der jeweiligen Betreiber. Der Anbieter hat bei der
                erstmaligen Verknüpfung der externen Links die fremden Inhalte
                daraufhin überprüft, ob etwaige Rechtsverstöße bestehen. Zu dem
                Zeitpunkt waren keine Rechtsverstöße ersichtlich. Der Anbieter
                hat keinerlei Einfluss auf die aktuelle und zukünftige
                Gestaltung und auf die Inhalte der verknüpften Seiten. Das
                Setzen von externen Links bedeutet nicht, dass sich der Anbieter
                die hinter dem Verweis oder Link liegenden Inhalte zu Eigen
                macht. Eine ständige Kontrolle der externen Links ist für den
                Anbieter ohne konkrete Hinweise auf Rechtsverstöße nicht
                zumutbar. Bei Kenntnis von Rechtsverstößen werden jedoch
                derartige externe Links unverzüglich gelöscht.
              </P>
              <Heading2>Urheber- und Leistungsschutzrechte</Heading2>
              <P>
                Die auf dieser Website veröffentlichten Inhalte unterliegen dem
                deutschen Urheber- und Leistungsschutzrecht. Jede vom deutschen
                Urheber- und Leistungsschutzrecht nicht zugelassene Verwertung
                bedarf der vorherigen schriftlichen Zustimmung des Anbieters
                oder jeweiligen Rechteinhabers. Dies gilt insbesondere für
                Vervielfältigung, Bearbeitung, Übersetzung, Einspeicherung,
                Verarbeitung bzw. Wiedergabe von Inhalten in Datenbanken oder
                anderen elektronischen Medien und Systemen. Inhalte und Rechte
                Dritter sind dabei als solche gekennzeichnet. Die unerlaubte
                Vervielfältigung oder Weitergabe einzelner Inhalte oder
                kompletter Seiten ist nicht gestattet und strafbar. Lediglich
                die Herstellung von Kopien und Downloads für den persönlichen,
                privaten und nicht kommerziellen Gebrauch ist erlaubt. Die
                Darstellung dieser Website in fremden Frames ist nur mit
                schriftlicher Erlaubnis zulässig.
              </P>
              <Heading2>Google Analytics</Heading2>
              <P>
                Diese Website benutzt Google Analytics, einen Webanalysedienst
                der Google Inc. („Google") Google Analytics verwendet sog.
                „Cookies", Textdateien, die auf Ihrem Computer gespeichert
                werden und die eine Analyse der Benutzung der Website durch Sie
                ermöglicht. Die durch den Cookie erzeugten Informationen über
                Ihre Benutzung diese Website (einschließlich Ihrer IP-Adresse)
                wird an einen Server von Google in den USA übertragen und dort
                gespeichert. Google wird diese Informationen benutzen, um Ihre
                Nutzung der Website auszuwerten, um Reports über die
                Websiteaktivitäten für die Websitebetreiber zusammenzustellen
                und um weitere mit der Websitenutzung und der Internetnutzung
                verbundene Dienstleistungen zu erbringen. Auch wird Google diese
                Informationen gegebenenfalls an Dritte übertragen, sofern dies
                gesetzlich vorgeschrieben oder soweit Dritte diese Daten im
                Auftrag von Google verarbeiten. Google wird in keinem Fall Ihre
                IP-Adresse mit anderen Daten der Google in Verbindung bringen.
                Sie können die Installation der Cookies durch eine entsprechende
                Einstellung Ihrer Browser Software verhindern; wir weisen Sie
                jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht
                sämtliche Funktionen dieser Website voll umfänglich nutzen
                können. Durch die Nutzung dieser Website erklären Sie sich mit
                der Bearbeitung der über Sie erhobenen Daten durch Google in der
                zuvor beschriebenen Art und Weise und zu dem zuvor benannten
                Zweck einverstanden.
              </P>
              <div style={{ marginBottom: "6rem" }}></div>
            </Column>
          </Row>
        </Container>
      </SecondarySection>
    </>
  )
}

const Heading2 = styled(H4Raw)`
  margin-top: 2rem;
`

const P = styled(Paragraph)`
  a {
    color: ${props => props.theme.palette.blue.vibrant};
  }
`

export default Imprint
